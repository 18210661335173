export const saveRulesToLocalStorage = (rules) => {
  if (rules.length !== 0) {
    localStorage.setItem('rules', JSON.stringify(rules));
  }
};

export const loadRulesFromLocalStorage = () => {
  const savedRules = localStorage.getItem('rules');
  return savedRules ? JSON.parse(savedRules) : [];
};
