import { Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import RuleInput from "./components/RuleInput";
import RuleList from "./components/RuleList";
import { getRandomRules } from "./utils/randomizer";
import {
  loadRulesFromLocalStorage,
  saveRulesToLocalStorage,
} from "./utils/rulesRepository";

const App = () => {
  const [rules, setRules] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);

  useEffect(() => {
    const loadedRules = loadRulesFromLocalStorage();
    setRules(loadedRules);
  }, []);

  useEffect(() => {
    saveRulesToLocalStorage(rules);
  }, [rules]);

  const clearSelectedRules = () => {
    setSelectedRules([]);
  };

  const randomizeRules = (amount) => {
    const newRandomRules = getRandomRules(rules, selectedRules, amount);
    setSelectedRules((prevSelectedRules) => [
      ...prevSelectedRules,
      ...newRandomRules,
    ]);
  };

  const addRule = (newRule) => {
    if (newRule.trim()) {
      setRules((prevRules) => [...prevRules, newRule]);
      setSelectedRules((prevSelectedRules) => [...prevSelectedRules, newRule]);
    }
  };

  const deleteRule = (ruleToDelete, isFromSelected) => {
    if (isFromSelected) {
      setSelectedRules(selectedRules.filter((rule) => rule !== ruleToDelete));
    } else {
      setRules(rules.filter((rule) => rule !== ruleToDelete));
    }
  };

  const exportRules = () => {
    const fileName = 'rules.json';
    const json = JSON.stringify(rules);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const importRules = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = e => {
      const importedRules = JSON.parse(e.target.result);
      setRules((prev)=> [...prev, ...importedRules]);
    };
  };

  return (
    <Container maxWidth="m">
      <Typography variant="h3" component="h1" gutterBottom>
        Super Smash Saufen Rule Randomizer
      </Typography>
      <RuleInput onAddRule={addRule} />
      <RuleList
        rules={selectedRules}
        title="Selected Rules"
        onDelete={(rule) => deleteRule(rule, true)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => randomizeRules(1)}
      >
        Get Random Rule
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_blank")
        }
      >
        Don't Click
      </Button>

      <Button variant="contained" color="error" onClick={clearSelectedRules}>
        Remove All Selected Rules
      </Button>
      <RuleList
        rules={rules}
        title="All Rules"
        onDelete={(rule) => deleteRule(rule, false)}
      />
      <Button variant="contained" onClick={exportRules}>
  Export Rules
</Button>
<input
  accept="application/json"
  type="file"
  onChange={importRules}
  style={{ display: 'none' }}
  id="raised-button-file"
/>
<label htmlFor="raised-button-file">
  <Button variant="contained" component="span">
    Import Rules
  </Button>
</label>
    </Container>
  );
};

export default App;
