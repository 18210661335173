export const getRandomRules = (rules, selectedRules, amount = 1) => {
  // Filter out the rules that are already selected
  const availableRules = rules.filter(rule => !selectedRules.includes(rule));

  // Shuffle the array of available rules
  const shuffledRules = [...availableRules].sort(() => Math.random() - 0.5);

  // If the amount requested is more than the number of available rules,
  // return the shuffled rules
  if (amount >= availableRules.length) {
    return shuffledRules;
  }

  // Otherwise, slice the array to get the specified amount of rules
  return shuffledRules.slice(0, amount);
};
