import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const RuleList = ({ rules, title, onDelete }) => {
  return (
    <>
      {title && <h3>{title}</h3>}
      <List>
        {rules.map((rule, index) => (
          <ListItem key={index} secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(rule)}>
              <DeleteIcon />
            </IconButton>
          }>
            <ListItemText primary={rule} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default RuleList;
