import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const RuleInput = ({ onAddRule }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onAddRule(inputValue);
    setInputValue(''); // Clear the input after submission
  };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        label="New Rule"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary">
        Add Rule
      </Button>
    </Box>
  );
};

export default RuleInput;
